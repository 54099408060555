.component-wrapper {
  :global {
    .ant-radio-button-wrapper {
      background-color: #eff6ff;
    }
  }
}
.modal-content {
  :global {
    input {
      border-radius: 30px;
    }
    .ant-select-selector {
      border-radius: 30px !important;
    }
    .ant-modal {
      @media (min-width: 768px) {
        top: 200px !important;
      }
    }
    .ant-modal-content {
      padding: 0 !important;
      background-color: transparent !important;
    }
    .ant-modal-header {
      background-color: transparent !important;
      margin-bottom: 0 !important;
    }
    .ant-modal-title {
      background-color: transparent !important;
    }
    .ant-modal {
      @media (min-width: 768px) {
        width: 560px !important;
      }
      @media (max-width: 768px) {
        width: 300px !important;
      }
    }
    .ant-modal-body {
      background-color: #fff;
      @media (min-width: 768px) {
        border-radius: 10px;
      }
    }
    .anticon-close {
      width: 15px;
      height: 15px;
      background-color: #fff;
      opacity: 0.5;
      border-radius: 50%;
      padding: 3px;
    }
    .ant-modal-close {
      top: 0px !important;
      right: 0px !important;
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-form-item {
      margin-bottom: 12px !important;
    }
  }
}
