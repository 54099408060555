.component-wrapper {
  :global {
    .ant-radio-button-wrapper {
      background-color: #eff6ff;
    }
    .ant-radio-button-wrapper {
      border-color: #fff;
      &::before {
        background-color: #fff !important;
      }
    }
    .ant-radio-button-wrapper-checked {
      background-color: #2660ff !important;
    }
  }
}
