.page-wrapper {
  .card-shadow {
    box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.1);
  }

  .list-point {
    position: relative;
    margin-left: 20px;
    &::before {
      content: "";
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      background: linear-gradient(135deg, rgba(125, 236, 255, 0.56) 0%, #386bfc 56%, #2660ff 100%);
      box-shadow: 1px 1px 1px 0px rgba(24, 144, 255, 0.44);
    }
  }

  .feature-corner-mark {
    background: linear-gradient(-60deg, transparent 16px, #2660ff 0);
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 8px;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-left: 8px solid #fff;
      border-bottom: 6px solid transparent;
      z-index: 1;
    }
  }

  .feature-beneficence {
    background: linear-gradient(270deg, rgba(38, 96, 255, 0) 0%, rgba(38, 96, 255, 0.8) 50%, rgba(38, 96, 255, 0) 100%);
  }
}
